import React, { useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import ptBR from 'date-fns/locale/pt-BR';
import { formatDate } from 'utils/formatter';
import { DateTime } from 'luxon';

registerLocale('pt-BR', ptBR);

interface IProps {
    label?: string;
    start?: Date | null;
    end?: Date | null;
    onStart(date?: Date | null): void;
    onEnd(date?: Date | null): void;
    minDate?: Date | null;
    firstPlaceholder?: string;
    secondPlaceholder?: string;
    maxRange?: number;
}

const today = DateTime.now().toJSDate();

const DP: React.FC<IProps> = ({ label, start, end, onStart, onEnd, minDate, firstPlaceholder, secondPlaceholder, maxRange }) => {
    const handleMaxRange = (startDate: Date, endDate: Date) => {
        const startISO = DateTime.fromJSDate(startDate).toISODate();
        const endISO = DateTime.fromJSDate(endDate).toISODate();

        const startDateTime = DateTime.fromISO(startISO);
        const endDateTime = DateTime.fromISO(endISO);
        const dateDiff = endDateTime.diff(startDateTime, 'days').toObject();

        return dateDiff.days;
    };

    const handleStartDate = () => {
        if (maxRange) {
            const dateDiff = handleMaxRange(start!, end!);

            if (dateDiff && Math.abs(dateDiff) > maxRange) {
                const maxEndDate = DateTime.fromJSDate(start!).plus({ days: maxRange }).toJSDate();
                onEnd(maxEndDate);
            } else if ((start && !end) || (start && end && start > end)) {
                onEnd(start);
            }
        } else {
            if (start && !end) {
                onEnd(today);
            }
            if (start && end) {
                if (end < start) onEnd(start);
            }
        }
    };

    const handleEndDate = () => {
        if (maxRange) {
            const dateDiff = handleMaxRange(start!, end!);

            if (dateDiff && Math.abs(dateDiff) > maxRange) {
                const maxStartDate = DateTime.fromJSDate(end!).minus({ days: maxRange }).toJSDate();
                onStart(maxStartDate);
            } else if ((!start && end) || (end && start && start > end)) {
                onStart(end);
            }
        } else {
            if (!start && end) {
                onStart(end);
            }
            if (end && start && start > end) {
                onStart(end);
            }
        }
    };

    const dateMask = useCallback((e) => {
        formatDate(e);
    }, []);

    return (
        <div className="abmex-datepicker">
            {label && <label htmlFor="date-picker">{label}</label>}

            <div className="picker">
                <DatePicker
                    id="date-picker"
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    // customInput={<ExampleCustomInput data-testid="dp-start" />}
                    selected={start}
                    onChange={(date) => onStart(date)}
                    onKeyDown={dateMask}
                    onBlur={handleStartDate}
                    onCalendarClose={handleStartDate}
                    selectsStart
                    startDate={start}
                    endDate={end}
                    minDate={minDate}
                    maxDate={DateTime.now().toJSDate()}
                    placeholderText={firstPlaceholder}
                    className="entrada"
                />
                <span>-</span>
                <DatePicker
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    // customInput={<ExampleCustomInput dataTestId="dp-end" />}
                    selected={end}
                    onChange={(date) => onEnd(date)}
                    onKeyDown={dateMask}
                    onBlur={handleEndDate}
                    onCalendarClose={handleEndDate}
                    selectsEnd
                    startDate={start}
                    endDate={end}
                    minDate={minDate}
                    maxDate={DateTime.now().toJSDate()}
                    placeholderText={secondPlaceholder}
                    popperPlacement="top-end"
                    className="entrada"
                />
            </div>
        </div>
    );
};

DP.defaultProps = {
    label: '',
    start: null,
    end: null,
    minDate: null,
    firstPlaceholder: 'de',
    secondPlaceholder: 'até',
    maxRange: undefined,
};

export default DP;
