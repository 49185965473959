import ContainerLoading from 'components/ContainerLoading';
import { Header, HeaderItem, Table } from 'components/Table';
import EmptyList from 'components/EmptyList';
import React, { createContext, useState } from 'react';
import Pagination from 'components/UIKit/Pagination';
import { getSessionItem, handleChangeSessionPage } from 'utils/getSessionInfo';
import { IListCategory, IProductList } from 'pages/Approvals/Products/types';
import { DateTime } from 'luxon';
import { calcPage } from 'utils/converter';
import { ProductServices } from 'services/api';
import CustomButton from 'components/CustomButton';
import { FaFilter } from 'react-icons/fa';
import { Heading } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { defaultValues } from './defaultValues';
import Filter from './Filter';
import { IProductsFilter } from './types';
import ProductsList from './ProductsList';
import FilterChip from './FilterChip';
import './styles.scss';

interface IContextProps {
    filterModal: IProductsFilter;
    filter: boolean;
    listCategory: IListCategory[];
    list: IProductList[];
    HandlerProduct: (id: number) => void;
    handlerSubmit: (data) => void;
    handlerProductType: () => void;
}

export const ContextProduct = createContext<IContextProps>({} as IContextProps);

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<IProductList[]>([]);
    const [actualPage, setActualPage] = useState(Number(getSessionItem('page-products', '1')));
    const [totalItens, setTotalItens] = useState(0);
    const [filter, setFilter] = useState(false);
    const [filterModal, setFilterModal] = useState<IProductsFilter>(defaultValues);
    const [listCategory, setListCategory] = useState<IListCategory[]>([]);

    const history = useHistory();
    const LIMIT = 10;

    const loadPage = async (options = filterModal) => {
        setLoading(true);

        const params = {
            status: options.status.map((element) => element.name).join(',') || undefined,
            created_from: options?.date?.mid ? DateTime.fromJSDate(options?.date?.mid).toFormat('yyyy-LL-dd') : undefined,
            created_to: options?.date?.mad ? DateTime.fromJSDate(options?.date?.mad).toFormat('yyyy-LL-dd') : undefined,
            product_type_id: options?.product_type_id && options?.product_type_id !== 0 ? options.product_type_id : undefined,
            product_id: options?.product_id ? options.product_id : undefined,
            producer: options?.producer ? options.producer : undefined,
            product_name: options?.product_name ? options.product_name : undefined,
            limit: LIMIT,
            offset: (actualPage - 1) * LIMIT,
        };

        ProductServices.get(params)
            .then((resp) => {
                setList(resp.data.rows);
                setTotalItens(resp.data.count);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    };

    const HandlerPagination = async (e: any, page: number) => {
        handleChangeSessionPage(page, 'page-products', setActualPage);
    };

    const HandlerProduct = (id: number) => {
        history.push(
            {
                pathname: '/approvals/product',
            },
            { id }
        );
    };

    const handlerProductType = () => {
        const saida: IListCategory[] = listCategory.filter((elem) => elem.id === Number(filterModal.product_type_id));
        return saida[0].name;
    };

    const handlerSubmit = async (data: IProductsFilter) => {
        const newData = data;
        if (newData.product_type_id === 0) {
            newData.product_type_id = undefined;
        }
        sessionStorage.setItem('filter_product', JSON.stringify(data));
        setFilterModal(newData);
        setFilter(false);
        HandlerPagination(null, 1);
        await loadPage(newData);
    };

    React.useEffect(() => {
        ProductServices.types
            .list()
            .then((resp) => {
                const newData = resp;
                newData.data = [{ id: 0, name: 'Todos' }, ...newData.data];
                setListCategory(newData.data);
            })
            .catch(() => {});
    }, [actualPage]);

    React.useEffect(() => {
        const filterOptions = sessionStorage.getItem('filter_product');
        if (filterOptions) {
            const filterOptionsParsed = JSON.parse(filterOptions);
            if (filterOptionsParsed.date.mid && filterOptionsParsed.date.mad) {
                filterOptionsParsed.date.mid = new Date(filterOptionsParsed.date.mid);
                filterOptionsParsed.date.mad = new Date(filterOptionsParsed.date.mad);
            }
            setFilterModal(filterOptionsParsed);
            loadPage(filterOptionsParsed);
        } else {
            loadPage();
        }
    }, [actualPage]);

    const contextValue = React.useMemo(
        () => ({
            filterModal,
            filter,
            listCategory,
            list,
            HandlerProduct,
            handlerSubmit,
            handlerProductType,
        }),
        [filterModal, filter, listCategory, list, HandlerProduct, handlerSubmit, handlerProductType]
    );

    return (
        <ContextProduct.Provider value={contextValue}>
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <Heading as="h2" size="lg">
                            Produtos
                        </Heading>
                        <span className="subtitle">Visualize todos os produtos da plataforma.</span>
                    </div>
                    <CustomButton icon={<FaFilter />} color="primary" variant="outlined" onClick={() => setFilter(true)}>
                        Filtrar
                    </CustomButton>
                </div>
                <div className="mt-4">
                    <FilterChip />
                </div>
                <div className="mt-4">
                    <ContainerLoading loading={loading}>
                        <Table>
                            <Header>
                                <HeaderItem className="col-4">Produto</HeaderItem>
                                <HeaderItem>Formato</HeaderItem>
                                <HeaderItem>Data Cadastro</HeaderItem>
                                <HeaderItem>Status</HeaderItem>
                                <HeaderItem>Açôes</HeaderItem>
                            </Header>

                            {list.length > 0 && <ProductsList />}
                            {list.length === 0 && <EmptyList />}
                        </Table>
                        <div className="d-flex flex-row justify-content-between my-5 paginacao">
                            <span>{calcPage(actualPage, LIMIT, totalItens)}</span>
                            <Pagination page={actualPage} onChange={HandlerPagination} count={Math.ceil(totalItens / LIMIT)} color="primary" />
                        </div>
                    </ContainerLoading>
                </div>
            </div>
            <Filter open={filter} onClose={() => setFilter(false)} onSubmit={handlerSubmit} />
        </ContextProduct.Provider>
    );
};

export default Products;
