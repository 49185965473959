/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import HeaderNav from 'components/HeaderNav';
import { connect } from 'react-redux';
import { setCollapseMenu } from 'redux/actions';
import './styles.scss';
import { IApplicationState } from 'redux/store';
import { Dispatch } from 'redux';
import { routesMenu } from 'routes.menu';
import NavCollapse from './NavCollapse';

interface StateProps {
    collapseMenu: boolean;
}

interface DispatchProps {
    onToggleNavigation: (openMenu: boolean) => void;
}

type IProps = StateProps & DispatchProps;

const SideBar: React.FC<IProps> = ({ collapseMenu, onToggleNavigation }) => {
    return (
        <aside className={collapseMenu ? 'mini' : ''}>
            <HeaderNav />

            <ul className="abmex-navbar">
                {routesMenu.items.map((element, _) => (
                    <NavCollapse key={element.id} item={element} />
                ))}
            </ul>
        </aside>
    );
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        collapseMenu: state.template.collapseMenu,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onToggleNavigation: (openMenu: boolean) => dispatch(setCollapseMenu(openMenu)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
