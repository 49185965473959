import React from 'react';
import Header from './Header';
import './styles.scss';
import Navigation from './Navigation';

interface IProps {
    small?: boolean;
    children?: any;
}

const AppContainerView: React.FC<IProps> = ({ children, small = false }) => {
    return (
        <div className="animate__animated animate__faster animate__fadeIn">
            <Header small={small} />

            {small ? (
                <div>{children}</div>
            ) : (
                <>
                    <Navigation />
                    <div className="abmex-main-container">
                        <div className="container">
                            <main>{children}</main>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

AppContainerView.defaultProps = {
    small: false,
    children: null,
};

export default AppContainerView;
