import { Avatar, IconButton } from '@material-ui/core';
import React from 'react';
import { FaFilter } from 'react-icons/fa';

const InfoFilter: React.FC = () => {
    return (
        <div className="mr-2">
            <Avatar
                style={{
                    backgroundColor: '#FFDFC2',
                    width: '2.3em',
                    height: '2.3em',
                }}
            >
                <IconButton color="primary">
                    <FaFilter color="#FF7A01" size={17} />
                </IconButton>
            </Avatar>
        </div>
    );
};

export default InfoFilter;
