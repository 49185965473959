import axios from 'axios';
import { application } from '../axios.service';

const baseURL = `${process.env.REACT_APP_API || 'https://api2.abmex.com.br'}/compliance`;

export interface IUserLogin {
    email: string;
    password: string;
    remember?: boolean;
}

export const ServiceSession = {
    login: (user: IUserLogin) => axios.post(`${baseURL}/session`, user),
    logout: () => application.delete('/session'),
    verify: (token: string) =>
        axios.get(`${baseURL}/session`, {
            headers: { Authorization: `Bearer ${token}` },
        }),
};
