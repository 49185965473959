import { Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { FaLock, FaTimesCircle } from 'react-icons/fa';
import './styles.scss';

interface IProps {
    onDelete?: ((event: any) => void) | undefined;
    disabled?: boolean;
    className?: string;
    label?: string | ReactNode;
    title?: string;
    testId?: string;
}

const CustomChip: React.FC<IProps> = ({ onDelete, disabled, className, label, testId, title = '' }) => {
    if (disabled) {
        return (
            <div className={`chip m-1 ${className}`}>
                <span>{label}</span>
                <Tooltip placement="top" arrow title="Esse filtro não pode ser excluído">
                    <span>
                        <FaLock color="black" />
                    </span>
                </Tooltip>
            </div>
        );
    }

    return (
        <Tooltip placement="top" arrow title={title}>
            <div className={`chip m-1 ${className}`}>
                <span>{label}</span>
                <button data-testId={testId} type="button" onClick={onDelete}>
                    <FaTimesCircle />
                </button>
            </div>
        </Tooltip>
    );
};

CustomChip.defaultProps = {
    onDelete: undefined,
    disabled: false,
    className: '',
    label: '',
    title: '',
    testId: 'close-button',
};

export default CustomChip;
