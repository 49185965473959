/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from 'redux/store';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { application, api } from '../services/axios.service';
import { IUserLogin, ServiceSession } from '../services/api/session';
import { setRoot, setUserInfo, setUserClean, setToken } from '../redux/actions';
import routes from '../routes';

interface IContext {
    authenticated: boolean;
    handleLogin: (userData, redirectURL?: any) => Promise<unknown>;
    handleLogout: () => void;
}

const AuthContext = createContext<IContext>({
    authenticated: false,
    handleLogin: () => {
        return new Promise((_, __) => {});
    },
    handleLogout: () => {},
});

const AuthProviderComponent = ({ children, setRoot, setUserInfo, setToken, setUserClean, token }) => {
    const history = useHistory();
    const [authenticated, setAuthenticated] = useState(false);
    const [, setLoading] = useState(true);
    const currentIndex = document.location.pathname;

    useEffect(() => {
        ServiceSession.verify(token)
            .then(() => {
                application.defaults.headers.Authorization = `Bearer ${token}`;
                api.defaults.headers.Authorization = `Bearer ${token}`;
                setAuthenticated(true);
                // Caso o usuário esteja entrando em uma rota especifica do sistema, ele mantem na rota
                // Caso a rota não exista, ele manda pra dashboard
                if (routes.filter((element) => element.path === currentIndex).length === 0) {
                    history.push(currentIndex);
                }
                setRoot('ROOT_INSIDE');
            })
            .catch(() => {
                setRoot('ROOT_OUTSIDE');
                setUserClean();
            });
    }, []);

    async function handleLogin(userData: IUserLogin, redirectURL = '/approvals') {
        return new Promise((res, rej) => {
            if (userData) {
                ServiceSession.login(userData)
                    .then((resp) => {
                        setUserInfo(resp.data.user, userData.remember);
                        setToken(resp.data.token, userData.remember);
                        application.defaults.headers.Authorization = `Bearer ${resp.data.token}`;
                        api.defaults.headers.Authorization = `Bearer ${resp.data.token}`;
                        setRoot('ROOT_INSIDE');
                        setAuthenticated(true);
                        history.push(redirectURL);
                        res({ auth: true, error: null });
                    })
                    .catch((err) => {
                        rej({
                            auth: false,
                            error: err?.response?.status,
                            code: err?.response?.data?.code,
                        });
                        setRoot('ROOT_OUTSIDE');
                    });
            }
        });
    }

    async function handleLogout() {
        setLoading(true);
        setRoot('ROOT_LOADING');
        ServiceSession.logout()
            .then(() => {
                try {
                    localStorage.removeItem('jwttoken');
                    setUserClean();
                    application.defaults.headers.Authorization = undefined;
                    api.defaults.headers.Authorization = undefined;
                    setRoot('ROOT_OUTSIDE');
                    history.push('/account/login');
                } catch (err: any) {
                    toast.error(err.message);
                }
            })
            .catch(() => {});
    }

    const contextValue = React.useMemo(() => ({ authenticated, handleLogin, handleLogout }), []);

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        root: state.app.root,
        user: state.app.user,
        token: state.app.token,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setRoot: (view) => dispatch(setRoot(view)),
        setUserInfo: (info, remember) => dispatch(setUserInfo(info, remember)),
        setUserClean: () => dispatch(setUserClean()),
        setToken: (token, remember) => dispatch(setToken(token, remember)),
    };
};

const AuthProvider = connect(mapStateToProps, mapDispatchToProps)(AuthProviderComponent);

export { AuthContext, AuthProvider };
