import React, { useState } from 'react';
import { ReactComponent as Ilustration } from '../../assets/images/unavailable_service.svg';
import './styles.scss';

interface IProps {
    error: Object | null;
}

const UnavailableService: React.FC<IProps> = ({ error }) => {
    const [displayCode, setDisplayCode] = useState(false);

    const HandlerDisplay = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setDisplayCode(!displayCode);
    };

    return (
        <div className="servico-indisponivel">
            <Ilustration />
            <div className="desc mt-4">
                <h3 className="my-2">Serviço indisponível!</h3>
                <span className="text-center">Serviço está temporariamente indisponível. Tente novamente mais tarde.</span>
                {error && (
                    <>
                        <a href="/" onClick={HandlerDisplay} className="mt-2">
                            Exibir informações técnicas
                        </a>
                        {displayCode && <pre className="erro mt-4">{typeof error === 'object' ? <code>{JSON.stringify(error, undefined, 2)}</code> : <code>{error}</code>}</pre>}
                    </>
                )}
            </div>
        </div>
    );
};

export default UnavailableService;
