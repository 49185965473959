import React from 'react';
import Lottie from 'react-lottie';
import { LinearProgress, makeStyles } from '@material-ui/core';
import animationData from '../../assets/lotties/intro.json';
import { ReactComponent as ReactLogo } from '../../assets/images/abmex_logo.svg';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&': {
            marginTop: theme.spacing(2),
        },
    },
}));

const IntroWeb = () => {
    const classes = useStyles();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="intro-container">
            <div className="centro">
                <Lottie width={300} options={defaultOptions} />
                <ReactLogo height={100} />
                <div className={classes.root}>
                    <LinearProgress />
                </div>
            </div>
        </div>
    );
};

export default IntroWeb;
