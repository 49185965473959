/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import * as S from './styles';
import InfoFilter from '../InfoFilter';
import LinkButton from '../LinkButton';

interface IProps {
    onClose: () => void;
    isOpen: boolean;
    btnClear?: () => void;
    children: React.ReactElement;
}

const Transition = React.forwardRef<unknown, TransitionProps & { children?: React.ReactElement }>(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const FilteredDialog: React.FC<IProps> = ({ onClose, isOpen, children, btnClear }) => {
    return (
        <S.ContainerDialog open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <S.Wrapper>
                <S.WrapperButton className="d-flex justify-content-end">
                    <S.CloseModal onClick={onClose}>
                        <FaTimes />
                    </S.CloseModal>
                </S.WrapperButton>
                <S.Header>
                    <div className="d-flex align-items-center" style={{ width: '3em', height: '3em' }}>
                        <InfoFilter />
                        <S.Title>Filtros</S.Title>
                    </div>
                    {btnClear && <LinkButton onClick={btnClear}>Limpar</LinkButton>}
                </S.Header>
                <div className="mt-2 h-100">{children}</div>
            </S.Wrapper>
        </S.ContainerDialog>
    );
};

FilteredDialog.prototype = {
    onClose: PropTypes.func,
    onOpen: PropTypes.bool,
    children: PropTypes.element,
    btnClear: PropTypes.func,
};

FilteredDialog.defaultProps = {
    btnClear: () => {},
};

Transition.defaultProps = {
    children: undefined,
};
export default FilteredDialog;
