import { createMuiTheme } from '@material-ui/core/styles';

export const themeConfig = createMuiTheme({
    palette: {
        primary: {
            light: '#FFBC3D',
            main: '#FF7a01',
            dark: '#FFA700', // será calculada com base palette.primary.main,
            contrastText: '#fff',
        },
        secondary: {
            light: '#cce6fa', // será calculada com base em palette.secondary.main,
            main: '#0081e6',
            dark: '#006bbf', // será calculada com base em palette.secondary.main,
            contrastText: '#fff', // será calculada para contrastar com palette.secondary.main
        },
        error: {
            light: '#e57373',
            main: '#FF5252',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#fff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        grey: {},
        action: {},
        text: {},
        background: {},
    },
    typography: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
});
