import React, { ReactNode } from 'react';
import { FaCheck, FaHandHoldingUsd, FaChartBar, FaUsers, FaCube, FaPuzzlePiece, FaMousePointer, FaUserFriends, FaCommentDots } from 'react-icons/fa';

// Readonly removido, verificar depois
export interface IItemMenu {
    id: string;
    title: string;
    type: string;
    url?: string;
    classes?: string;
    icon?: ReactNode;
    disabled?: boolean;
    children?: IItemMenu[];
}

interface IRouterMenu {
    items: IItemMenu[];
}

const routesMenu: IRouterMenu = {
    items: [
        {
            id: 'approvals',
            title: 'Aprovações',
            type: 'item',
            url: '/approvals',
            classes: 'nav-item',
            icon: <FaCheck />,
        },
        {
            id: 'reports',
            title: 'Relatórios',
            type: 'item',
            url: '/reports',
            classes: 'nav-item',
            icon: <FaChartBar />,
        },
        {
            id: 'refund',
            title: 'Reembolsos',
            type: 'item',
            url: '/refund',
            classes: 'nav-item',
            icon: <FaHandHoldingUsd />,
        },
        {
            id: 'users',
            title: 'Usuarios',
            type: 'item',
            url: '/users',
            classes: 'nav-item',
            icon: <FaUsers />,
        },
        {
            id: 'products',
            title: 'Produtos',
            type: 'item',
            url: '/products',
            classes: 'nav-item',
            icon: <FaCube />,
        },
        {
            id: 'integrations',
            title: 'Integrações',
            type: 'item',
            url: '/integrations',
            classes: 'nav-item',
            icon: <FaPuzzlePiece />,
        },
        {
            id: 'indicate',
            title: 'Indique e ganhe',
            type: 'collapse',
            icon: <FaMousePointer />,
            children: [
                {
                    id: 'indicate-control',
                    title: 'Configurações',
                    type: 'nav-item',
                    url: '/indicate/control',
                },
                {
                    id: 'indicate-list',
                    title: 'Relatório',
                    type: 'nav-item',
                    disabled: true,
                    url: '/indicate/list',
                },
            ],
        },
        {
            id: 'club',
            title: 'Abmex Club',
            type: 'item',
            url: '/club',
            classes: 'nav-item',
            icon: <FaUserFriends />,
        },
        {
            id: 'communications',
            title: 'Comunicação',
            type: 'collapse',
            icon: <FaCommentDots />,
            children: [
                {
                    id: 'communications-messages',
                    title: 'Pop-up informativo',
                    type: 'nav-item',
                    url: '/communications/messages',
                },
            ],
        },
    ],
};

export { routesMenu };
