/* eslint-disable max-len */
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import AvatarDefault from '../../assets/images/default_avatar.png';
import './styles.scss';

interface IProps {
    icon?: string;
    name: string;
    label: string;
    wpp?: string;
    top?: string;
}

const SmallPreview: React.FC<IProps> = ({ icon, name, label, wpp, top }) => {
    return (
        <div className="small-preview-abmex">
            <div className={`img-avatar ${top !== '' && 'big'}`}>{icon ? <img src={icon} alt={`Imagem referente a ${name}`} /> : <img src={AvatarDefault} alt={`Imagem referente a ${name}`} />}</div>

            <div className="desc">
                <span>{top}</span>
                <div className="title">
                    <b>{name}</b>
                    {wpp && (
                        <a href={`https://api.whatsapp.com/send?phone=${wpp}`} target="_blank" rel="noreferrer">
                            <FaWhatsapp />
                        </a>
                    )}
                </div>
                <span>{label}</span>
            </div>
        </div>
    );
};

SmallPreview.defaultProps = {
    icon: '',
    wpp: '',
    top: '',
};

SmallPreview.prototype = {
    icon: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    wpp: PropTypes.string,
};

export default SmallPreview;
