import { makeStyles } from '@material-ui/core';
import PaginationDefault from '@material-ui/lab/Pagination';
import PaginationItemDefault from '@material-ui/lab/PaginationItem';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: '40px',
    },
    selected: {},
    textSecondary: {},
});

export default function Pagination(props) {
    const renderItem = (item) => <PaginationItemDefault className="page-link" {...item} />;
    return <PaginationDefault renderItem={renderItem} className={useStyles('pagination').root} {...props} />;
}
