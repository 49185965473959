import SmallPreview from 'components/SmallPreview';
import { BodyAction, BodyActionBox, BodyContent, BodyItem } from 'components/Table';
import TextInfo from 'components/TextInfo';
import { StatusProduct } from 'components/Status';
import { DateTime } from 'luxon';
import { FaEye } from 'react-icons/fa';
import React, { useContext } from 'react';
import { ContextProduct } from '..';
import './styles.scss';

const ProductsList = () => {
    const { list, HandlerProduct } = useContext(ContextProduct);

    return (
        <>
            {list.map((element, _) => (
                <BodyContent row key={element.id} className={element.has_changed ? 'product-list-item' : ''}>
                    <BodyItem className="col-4">
                        <SmallPreview icon={element.avatar?.url} name={element.name} label={`ID : ${element.id}`} />
                    </BodyItem>
                    <BodyItem>
                        <TextInfo title={element.product_type.product_type} label={element.product_type.name} inverter />
                    </BodyItem>
                    <BodyItem className="text-semi-bold">{DateTime.fromSQL(element.created_at).toFormat('dd/LL/yyyy')}</BodyItem>
                    <BodyItem>
                        <StatusProduct status={element.status} />
                    </BodyItem>
                    <BodyAction>
                        <BodyActionBox onClick={() => HandlerProduct(element.id)} icon={<FaEye />} color="info" />
                    </BodyAction>
                </BodyContent>
            ))}
        </>
    );
};

export default ProductsList;
