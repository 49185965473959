import React from 'react';
import './styles.scss';

interface IProps {
    status: string | null;
}

const StatusUser: React.FC<IProps> = ({ status }) => {
    if (status === 'approved_operator') {
        return (
            <div className="status-item success">
                <span>Aprovado</span>
            </div>
        );
    }
    if (status === 'approved_abmex') {
        return (
            <div className="status-item success">
                <span>Aprovado Abmex</span>
            </div>
        );
    }
    if (status === 'disapproved_abmex') {
        return (
            <div className="status-item danger">
                <span>Reprovado Abmex</span>
            </div>
        );
    }
    if (status === 'disapproved_operator') {
        return (
            <div className="status-item danger">
                <span>Reprovado Operadora</span>
            </div>
        );
    }
    if (status === 'in_analysis') {
        return (
            <div className="status-item warning">
                <span>Pendente</span>
            </div>
        );
    }
    if (status === 'pending') {
        return (
            <div className="status-item neutral">
                <span>Cadastro Inicial</span>
            </div>
        );
    }
    if (status === 'original_email') {
        return (
            <div className="status-item neutral">
                <span className="text-uppercase">E-mail Original</span>
            </div>
        );
    }
    return (
        <div className="status-item neutral">
            <span>? {status} ?</span>
        </div>
    );
};

export { StatusUser };
