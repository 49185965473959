interface IGetSessionItem {
    (sessionName: string, defaultValue?: string): string;
}

export const getSessionItem: IGetSessionItem = (sessionName, defaultValue = '') => {
    const item = sessionStorage.getItem(sessionName);
    return item || defaultValue;
};

interface IHandleChangeSessionOption {
    (value: string, option: string, setOption: (opt: string) => void): void;
}

export const handleChangeSessionOption: IHandleChangeSessionOption = (value, option, setOption) => {
    setOption(value);
    sessionStorage.setItem(option, value);
};

interface IHandleChangeSessionPage {
    (value: number, page: string, setPage: (opt: number) => void): void;
}

export const handleChangeSessionPage: IHandleChangeSessionPage = (value, page, setPage) => {
    setPage(value);
    sessionStorage.setItem(page, String(value));
};
