/* eslint-disable import/no-extraneous-dependencies */
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Dispatch } from 'redux';
import AppContainerView from './pages/RootContainer';
import IntroView from './pages/IntroContainer';
import ModalUnavailableService from './pages/ModalUnavailable';
import { themeConfig } from './assets/theme.config';
import OutsideStack from './stack/OutsideStack';
import InsideStack from './stack/InsideStack';
import { AuthProvider } from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { IApplicationState } from './redux/store';
import { setRoot } from './redux/actions';

interface IProps {
    root: string;
}

const theme = extendTheme({
    shadows: {
        outline: 'none',
    },
    fonts: {
        heading: 'Inter, sans-serif',
        body: 'Inter, sans-serif',
    },
    colors: {
        orange: {
            500: '#FF7A01',
        },
    },
});

const AppContainer: React.FC<IProps> = ({ root }) => {
    return (
        <>
            <AuthProvider>
                <ThemeProvider theme={themeConfig}>
                    <ChakraProvider theme={theme}>
                        <>
                            {root === 'ROOT_LOADING' ? <IntroView /> : null}
                            {root === 'ROOT_OUTSIDE' ? <OutsideStack /> : null}
                            {root === 'ROOT_INSIDE' ? (
                                <AppContainerView>
                                    <InsideStack />
                                </AppContainerView>
                            ) : null}
                        </>
                        <ModalUnavailableService />
                    </ChakraProvider>
                </ThemeProvider>
            </AuthProvider>
            <ToastContainer position="top-center" />
        </>
    );
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        root: state.app.root,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setRoot: (view: string) => dispatch(setRoot(view)), // Revisar type do parametro 'view'
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
