import { application } from './axios.service';

export function CrudService(endpoint: string) {
    return {
        create: (data: Object, id: number) => application.post(id ? `${endpoint}/${id}` : endpoint, data),
        read: (id: number | null, data?: Object, extra = '') => application.get(id ? `${endpoint}/${id}/${extra}` : endpoint, data),
        update: (id: number, data: Object) => application.put(`${endpoint}/${id}`, data),
        delete: (id: number) => application.delete(`${endpoint}/${id}`),
    };
}
