/* eslint-disable prettier/prettier */
import { IFilterParams } from 'pages/Approvals/Users';
import { IApprovedPayload } from 'pages/ApprovalsUser/Modal.Accept';
import { IReprovedPayload } from 'pages/ApprovalsUser/Modal.Reject';
import { IEmailsResponse, IReservePayload, IReserveSettings } from 'services/types/users';
import { application } from '../axios.service';

export const UsersService = {
    get: (params: IFilterParams) => application.get('/users', { params }),
    find: (id: number) => application.get(`/users/${id}`),
    getLogs: (id: number) => application.get<IEmailsResponse>(`/users/${id}/changes-email-history`),
    reproved: (data: IReprovedPayload) => application.put('/users/status/disapprove', data),
    approved: (data: IApprovedPayload) => application.put('/users/status/approve', data),
    getRole: (userId: number) => application.get(`/users/${userId}/role`),
    updateRole: (userId: number, roleName: string) => application.put(`/users/${userId}/role`, { role_name: roleName }),
    getSettings: (userId: number) => application.get(`/users/${userId}/settings`),
    getReserveSettings: (userId: number) => application.get<IReserveSettings>(`/users/${userId}/settings/blocked-balance`),
    updateSettings: (userId: number, settings: any) => application.put(`/users/${userId}/settings`, settings),
    updateReserveSettings: (userId: number, settings: IReservePayload) => application.put(`/users/${userId}/settings/blocked-balance`, settings),
    disable: (userId: number) => application.put(`/users/${userId}/deactivate`),
    changeEmail: (userId: number, email: string) => application.put(`users/${userId}/change-email`, { email }),
    getUserAsaasOnboardingUrl: (userId: number) => application.get(`/users/${userId}/get-asaas-onboarding-url`),
};
