import React from 'react';
import './styles.scss';

interface IProps {
    status: string;
    size?: 'sm';
}

const StatusRefund: React.FC<IProps> = ({ status, size }) => {
    if (status === 'approved') {
        return (
            <div className={`status-item success ${size || ''}`}>
                <span>Aprovado</span>
            </div>
        );
    }
    if (status === 'paid') {
        return (
            <div className={`status-item success ${size || ''}`}>
                <span>Pago</span>
            </div>
        );
    }
    if (status === 'captured  ') {
        return (
            <div className={`status-item success ${size || ''}`}>
                <span>Pagamento aprovado</span>
            </div>
        );
    }
    if (status === 'paused') {
        return (
            <div className={`status-item warning ${size || ''}`}>
                <span>Pausado</span>
            </div>
        );
    }
    if (status === 'pending') {
        return (
            <div className={`status-item warning ${size || ''}`}>
                <span>Pendente</span>
            </div>
        );
    }
    if (status === 'rascunho') {
        return (
            <div className={`status-item neutral ${size || ''}`}>
                <span>Rascunho</span>
            </div>
        );
    }
    if (status === 'disapproved' || status === 'reproved') {
        return (
            <div className={`status-item danger ${size || ''}`}>
                <span>Reprovado</span>
            </div>
        );
    }
    if (status === 'unauthorized') {
        return (
            <div className={`status-item danger ${size || ''}`}>
                <span>Transação Negada</span>
            </div>
        );
    }
    if (status === 'expired') {
        return (
            <div className={`status-item danger ${size || ''}`}>
                <span>Boleto Vencido</span>
            </div>
        );
    }
    if (status === 'canceled') {
        return (
            <div className={`status-item danger ${size || ''}`}>
                <span>Cancelado</span>
            </div>
        );
    }
    if (status === 'waiting') {
        return (
            <div className={`status-item info ${size || ''}`}>
                <span>Em análise</span>
            </div>
        );
    }
    return (
        <div className={`status-item neutral ${size || ''}`}>
            <span>? {status} ?</span>
        </div>
    );
};

StatusRefund.defaultProps = {
    size: 'sm',
};

export { StatusRefund };
