import { IChargebackDetails, IChargebacksResponse, IInvoiceRefundData, IInvoiceDetails, IInvoicesResponse, IWithdrawalsResponse } from 'services/types/reports';
import { application } from '../axios.service';

export const ServiceReports = {
    getGeneralDate: () => application.get('/withdrawals/dashboard'),
    getWithdrawals: (params: any) => application.get<IWithdrawalsResponse>('/withdrawals', { params }),

    getChargebacks: (params: any) => application.get<IChargebacksResponse>('/chargebacks', { params }),
    getChargeback: (id: string) => application.get<IChargebackDetails>(`/chargebacks/${id}`),
    getChargebackPDF: (id: string) => application.get(`/chargebacks/${id}/export`, { responseType: 'blob' }),
    updateChargeback: (id: string, sendDocs: boolean) => application.put(`/chargebacks/${id}`, { sendDocs }),

    getInvoices: (params: any) => application.get<IInvoicesResponse>('/invoices', { params }),
    getInvoice: (id) => application.get<IInvoiceDetails>(`/invoices/${id}`),
    invoiceRefund: (id, data: IInvoiceRefundData) => application.post(`/refunds/invoice/${id}`, data),
};
