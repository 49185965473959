/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import { ProductStatus } from 'constant';
import FilteredDialog from 'components/FilteredDialog';
import ContainerLoading from 'components/ContainerLoading';
import ChoiseTag from 'components/ChoiceTag';
import DatePicker from 'components/DatePicker';
import CustomButton from 'components/CustomButton';
import { IProductFilterModal } from 'pages/Approvals/Products/types';
import { ContextProduct } from '.';
import { defaultValues } from './defaultValues';

interface IProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values) => void;
}

const schema = yup.object().shape({
    product_name: yup.string().matches(/^(?! +)/, 'Não escreva espaços em branco'),
    producer: yup.string().matches(/^(?! +)/, 'Não escreva espaços em branco'),
});

const Filter: React.FC<IProps> = ({ open, onClose, onSubmit }) => {
    const { filterModal, filter, listCategory } = useContext(ContextProduct);

    const [loading] = useState(false);

    const { handleSubmit, control, reset } = useForm<IProductFilterModal>({
        resolver: yupResolver(schema),
        defaultValues,
        shouldUnregister: false,
    });

    React.useEffect(() => {
        if (filterModal) {
            reset({ ...filterModal });
        }
    }, [filterModal, filter]);

    const OptionsGenerator = Object.keys(ProductStatus).map((element) => ({ label: ProductStatus[element], name: element }));

    return (
        <FilteredDialog isOpen={open} onClose={onClose}>
            <ContainerLoading loading={loading}>
                <div className="overflow-auto">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            name="status"
                            render={({ value, onChange }) => (
                                <ChoiseTag className="my-4" value={value || []} multiple label={<span className="text-semi-bold">Status</span>} onSelect={(e) => onChange(e)} options={OptionsGenerator} />
                            )}
                        />
                        <Form.Group>
                            <Form.Label className="text-semi-bold">Data do Cadastro</Form.Label>
                            <Controller
                                control={control}
                                name="date"
                                render={({ value, onChange }) => <DatePicker start={value.mid} end={value.mad} onStart={(e) => onChange({ mid: e, mad: value.mad })} onEnd={(e) => onChange({ mad: e, mid: value.mid })} />}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-semi-bold">ID do produto</Form.Label>
                            <Controller name="product_id" control={control} placeholder="ID do produto" as={<Form.Control maxLength={100} type="number" />} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-semi-bold">Nome do produto</Form.Label>
                            <Controller name="product_name" control={control} placeholder="Nome do produto" as={<Form.Control maxLength={100} />} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-semi-bold">Produtor</Form.Label>
                            <Controller name="producer" control={control} placeholder="Produtor" as={<Form.Control maxLength={100} />} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-semi-bold">Formatos</Form.Label>
                            <Controller
                                control={control}
                                name="product_type_id"
                                custom
                                as={
                                    <Form.Control as="select">
                                        {listCategory.map((element, _) => (
                                            <option key={element.id} value={element.id}>
                                                {element.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                }
                            />
                        </Form.Group>
                        <div className="mt-4 mb-4 d-flex justify-content-center">
                            <CustomButton type="submit" color="primary">
                                Aplicar Filtro
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </ContainerLoading>
        </FilteredDialog>
    );
};

export default Filter;
