import React from 'react';
import { CircularProgress } from '@material-ui/core';

interface IProps {
    loading: boolean;
    children: any;
}

const ContainerLoading: React.FC<IProps> = ({ loading = true, children }) => {
    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100 m-5">
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <div>{children}</div>
            )}
        </div>
    );
};

export default ContainerLoading;
