// Templete
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const SET_ROOT_VIEW = 'SET_ROOT_VIEW';
export const SET_ERROR_API = 'SET_ERROR_API';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_CLEAN = 'SET_USER_CLEAN';
export const SET_TOKEN = 'SET_TOKEN';
