import React from 'react';
import './styles.scss';

interface IProps {
    status: 'success' | 'failed';
}

const StatusIntegration: React.FC<IProps> = ({ status }) => {
    if (status === 'success') {
        return (
            <div className="status-item width-fixed success">
                <span>Sucesso</span>
            </div>
        );
    }
    if (status === 'failed') {
        return (
            <div className="status-item width-fixed danger">
                <span>Falha</span>
            </div>
        );
    }
    return (
        <div className="status-item width-fixed neutral">
            <span>? {status} ?</span>
        </div>
    );
};

export { StatusIntegration };
