import Products from 'pages/Products';
import React from 'react';

const ApprovalsView = React.lazy(() => import('./pages/Approvals'));
const ApprovalsUserView = React.lazy(() => import('./pages/ApprovalsUser'));
const ApprovalsProductView = React.lazy(() => import('./pages/ApprovalsProduct'));
const RefundListView = React.lazy(() => import('./pages/RefundList'));
const RefundDetailsView = React.lazy(() => import('./pages/RefundDetails'));
const ReportsView = React.lazy(() => import('./pages/Reports'));
const UsersView = React.lazy(() => import('./pages/User'));
const UsersDetailsView = React.lazy(() => import('./pages/UserDetails'));
const WithdrawalDetails = React.lazy(() => import('./pages/WithdrawalDetails'));
const Integrations = React.lazy(() => import('./pages/Integrations'));
const IndicateControl = React.lazy(() => import('./pages/IndicateAndWin/Control'));
const IndicateDetails = React.lazy(() => import('./pages/IndicateAndWin/Details'));
const IndicateList = React.lazy(() => import('./pages/IndicateAndWin/Report'));
const AbmexClub = React.lazy(() => import('./pages/Club'));
const Communication = React.lazy(() => import('./pages/Communication'));
const routes = [
    {
        path: ['/approvals', '/'],
        exact: true,
        name: 'Aprovações',
        component: ApprovalsView,
    },
    {
        path: '/approvals/user',
        exact: true,
        name: 'Aprovações Usuário',
        component: ApprovalsUserView,
    },
    {
        path: '/approvals/product',
        exact: true,
        name: 'Aprovações de Usuário',
        component: ApprovalsProductView,
    },
    {
        path: '/reports',
        exact: true,
        name: 'Relatórios',
        component: ReportsView,
    },
    {
        path: '/refund',
        exact: true,
        name: 'Reembolso',
        component: RefundListView,
    },
    {
        path: '/users',
        exact: true,
        name: 'Usuarios',
        component: UsersView,
    },
    {
        path: '/users/details',
        exact: true,
        name: 'Detalhes do Usuarios',
        component: UsersDetailsView,
    },
    {
        path: '/refund/details',
        exact: true,
        name: 'Reembolso detalhes',
        component: RefundDetailsView,
    },
    {
        path: '/withdrawal/details',
        exact: true,
        name: 'Saque detalhes',
        component: WithdrawalDetails,
    },
    {
        path: '/products',
        exact: true,
        name: 'Produtos',
        component: Products,
    },
    {
        path: '/integrations',
        exact: true,
        name: 'Integrações',
        component: Integrations,
    },
    {
        path: '/indicate/control',
        exact: true,
        name: 'Indique e ganhe',
        component: IndicateControl,
    },
    {
        path: '/indicate/list',
        exact: true,
        name: 'Lista de Indicações',
        component: IndicateList,
    },
    {
        path: '/indicate/details',
        exact: true,
        name: 'Detalhes dos Indicados',
        component: IndicateDetails,
    },
    {
        path: '/club',
        exact: true,
        name: 'Abmex Club',
        component: AbmexClub,
    },
    {
        path: '/communications/messages',
        exact: true,
        name: 'Comunicação',
        component: Communication,
    },
];
export default routes;
