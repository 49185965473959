import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import { IAppState, ITemplateState } from './types';

const middlewares = [thunk];

export interface IApplicationState {
    app: IAppState;
    template: ITemplateState;
}

const store: Store<IApplicationState> = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
