export const WithdrawalStatus = {
    pending: 'Pendente',
    approved: 'Aprovado',
    canceled: 'Cancelado',
};

export const InvoicesStatus = {
    pending: 'Pendente',
    paid: 'Paga',
    expired: 'Expirada (Vencida)',
    canceled: 'Cancelada',
    offer: 'Oferta',
    in_protest: 'Em Protesto',
    chargeback: 'Chargeback',
    refunded: 'Reembolsada',
    in_refunded: 'Em Recuperação',
    recovered: 'Recuperada',
    recovery: 'Recuperação',
};

export const InvoicesOperator = {
    mercadopago: 'Mercado Pago',
    asaas: 'ASAAS',
    hopypay: 'Hopypay',
};

export const ChargebackOperator = {
    mercadopago: 'Mercado Pago',
    asaas: 'ASAAS',
};

export const RefundStatus = {
    pending: 'Pendente',
    approved: 'Aprovado',
};

export const PaymentType = {
    credit_card: 'Cartão de crédito',
    bank_slip: 'Boleto',
    pix: 'Pix',
};

export const ProductStatus = {
    waiting: 'Pendente',
    approved: 'Aprovado',
    disapproved: 'Reprovado',
    paused: 'Pausado',
};

export const AccountStatus = {
    pending: 'Cadastro Inicial',
    in_analysis: 'Pendente',
    disapproved_abmex: 'Reprovado Abmex',
    disapproved_operator: 'Reprovado Operadora',
};

export const maxDateRange = 31;
