import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../components/Loader';
import routes from '../routes';

const InsideStack = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {routes.map((element, _) => (
                    <Route key={element.name} exact={element.exact} path={element.path} component={element.component} />
                ))}
            </Switch>
        </Suspense>
    );
};

export default InsideStack;
