/* eslint-disable react/button-has-type */
import React from 'react';
import { CircularProgress } from '@material-ui/core';

import * as S from './styles';

interface IProps {
    loading?: boolean;
    className?: string;
    color?: 'primary' | 'info' | 'success' | 'done' | 'danger' | 'warning' | 'secondary' | 'dark' | 'light';
    disabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'text' | 'outlined' | 'contained';
    icon?: React.ReactNode;
}

const CustomButton: React.FC<IProps> = ({ children, className, color, disabled, onClick, type, variant, loading, icon }) => {
    const renderClass = () => {
        if (variant === 'outlined') {
            return `btn-outline-`;
        }
        if (variant === 'text') {
            return `btn-text-`;
        }
        return `btn-`;
    };

    return (
        <button className={[className, 'btn', renderClass() + color].join(' ')} disabled={disabled} onClick={onClick} type={type}>
            <div className="d-flex align-items-center justify-content-center">
                {icon && <S.Icon>{icon}</S.Icon>}
                {loading && <CircularProgress className="mr-2" color="inherit" size={18} />}
                {children}
            </div>
        </button>
    );
};

CustomButton.defaultProps = {
    loading: false,
    className: '',
    color: 'primary',
    disabled: false,
    onClick: () => {},
    children: '',
    type: 'submit',
    icon: '',
    variant: 'contained',
};

export default CustomButton;
