import styled from 'styled-components';

export const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1em;
    border-radius: 2em;
    height: 2.5em;
    &:hover {
        background-color: #f5f2f2 !important;
    }
`;

export const Link = styled.button`
    color: ${(props) => props.color} !important;
    font-weight: 600;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
`;
