/* eslint-disable import/no-mutable-exports */
import React, { useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setRoot as setRootRedux, setErrorApi as setErrorApiRedux, setUserClean as SetUserCleanRedux } from '../redux/actions';
import { IApplicationState } from '../redux/store';
import { IAction } from '../redux/types';

interface IProps {
    setErrorApi: (error: string) => IAction;
    setRoot: (view: string) => IAction;
    setUserClean: () => IAction;
}

let application: AxiosInstance;
let api: AxiosInstance;

const Setup: React.FC<IProps> = ({ setErrorApi, setRoot, setUserClean }) => {
    const history = useHistory();

    useEffect(() => {
        application = axios.create({
            baseURL: `${process.env.REACT_APP_API || 'https://api2.abmex.com.br'}/compliance`,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        application.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error && error.response) {
                    if (error.response.status === 401 && error.response.data.error === 'Token invalid.') {
                        setRoot('ROOT_OUTSIDE');
                        setUserClean();
                        toast.error('Sessão expirada');
                        history.replace('/');
                    } else if (error.response.status === 500) {
                        toast.error('Erro interno do servidor');

                        if (error) {
                            setErrorApi(error?.response || true);
                        } else {
                            history.replace('/internalerro');
                        }
                    }

                    throw error;
                }
            }
        );

        api = axios.create({
            baseURL: process.env.REACT_APP_API || 'https://api2.abmex.com.br',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        api.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error && error.response) {
                    if (error.response.status === 401 && error.response.data.error === 'Token invalid.') {
                        setRoot('ROOT_OUTSIDE');
                        setUserClean();
                        toast.error('Sessão expirada');
                        history.replace('/');
                    } else if (error.response.status === 500) {
                        toast.error('Erro interno do servidor');

                        if (error) {
                            setErrorApi(error?.response || true);
                        } else {
                            history.replace('/internalerro');
                        }
                    }

                    throw error;
                }
            }
        );
    }, []);

    return null;
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        error: state.app.error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setErrorApi: (error: string) => dispatch(setRootRedux(error)),
        setRoot: (view: string) => dispatch(setErrorApiRedux(view)),
        setUserClean: () => dispatch(SetUserCleanRedux()),
    };
};

const SetupConnect = connect(mapStateToProps, mapDispatchToProps)(Setup);

export { SetupConnect, application, api };
