/* eslint-disable max-len */
import React, { useContext } from 'react';
import { ProductStatus } from 'constant';
import { DateTime } from 'luxon';
import CustomChip from 'components/CustomChip';
import { ContextProduct } from '..';

const FilterChip = () => {
    const { filterModal, handlerSubmit, handlerProductType } = useContext(ContextProduct);

    return (
        <div className="chips-select">
            {filterModal.status.length > 0 ? (
                <div>
                    {filterModal.status.length === 1 ? (
                        <CustomChip
                            className="m-1"
                            label={`Status : ${ProductStatus[filterModal.status[0].name]}`}
                            onDelete={() =>
                                handlerSubmit({
                                    ...filterModal,
                                    ...{
                                        status: [],
                                    },
                                })
                            }
                        />
                    ) : (
                        <CustomChip
                            title={filterModal.status.map((element) => ProductStatus[element.name]).join(' - ')}
                            className="m-1"
                            label={`Status : ${ProductStatus[filterModal.status[0].name]} + ${filterModal.status.length - 1} `}
                            onDelete={() =>
                                handlerSubmit({
                                    ...filterModal,
                                    ...{
                                        status: [],
                                    },
                                })
                            }
                        />
                    )}
                </div>
            ) : null}

            {filterModal?.product_type_id && (
                <CustomChip
                    label={`Formato : ${handlerProductType()}`}
                    onDelete={() => {
                        handlerSubmit({
                            ...filterModal,
                            product_type_id: undefined,
                        });
                    }}
                />
            )}

            {filterModal?.product_id && (
                <CustomChip
                    label={`ID do produto: ${filterModal?.product_id}`}
                    onDelete={() => {
                        handlerSubmit({
                            ...filterModal,
                            product_id: undefined,
                        });
                    }}
                />
            )}

            {filterModal?.product_name && (
                <CustomChip
                    label={`Nome do produto: ${filterModal?.product_name}`}
                    onDelete={() => {
                        handlerSubmit({
                            ...filterModal,
                            product_name: undefined,
                        });
                    }}
                />
            )}

            {filterModal?.producer && (
                <CustomChip
                    label={`Nome do produtor: ${filterModal?.producer}`}
                    onDelete={() => {
                        handlerSubmit({
                            ...filterModal,
                            producer: undefined,
                        });
                    }}
                />
            )}

            {filterModal?.date.mid && filterModal?.date.mad && (
                <CustomChip
                    label={`Data pedido : ${DateTime.fromJSDate(filterModal?.date.mid).toFormat('dd/LL/yyyy')} - ${DateTime.fromJSDate(filterModal?.date.mad).toFormat('dd/LL/yyyy')}`}
                    onDelete={() => {
                        handlerSubmit({
                            ...filterModal,
                            date: { mid: null, mad: null },
                        });
                    }}
                />
            )}
        </div>
    );
};

export default FilterChip;
