import React from 'react';
import './styles.scss';

interface IProps {
    status: string;
}

const StatusWithdraw: React.FC<IProps> = ({ status }) => {
    if (status === 'pending') {
        return (
            <div className="status-item warning">
                <span>Pendente (Asaas)</span>
            </div>
        );
    }
    if (status === 'pending_platform') {
        return (
            <div className="status-item warning">
                <span>Pendente (Abmex)</span>
            </div>
        );
    }
    if (status === 'approved') {
        return (
            <div className="status-item success">
                <span>Transferido</span>
            </div>
        );
    }
    if (status === 'canceled') {
        return (
            <div className="status-item danger">
                <span>Cancelado</span>
            </div>
        );
    }
    return (
        <div className="status-item neutral">
            <span>{`? ${status} ?`}</span>
        </div>
    );
};

export { StatusWithdraw };
