import * as actionsTypes from './actionsTypes';
import { IAction, IUser } from './types';

export const setRoot = (view: string): IAction => {
    return {
        type: actionsTypes.SET_ROOT_VIEW,
        payload: view,
    };
};

export const setErrorApi = (error: Object | null): IAction => {
    return {
        type: actionsTypes.SET_ERROR_API,
        payload: error,
    };
};

export const setCollapseMenu = (openMenu: boolean): IAction => {
    localStorage.setItem('CollapseMenu', String(openMenu));
    return {
        type: actionsTypes.COLLAPSE_MENU,
        payload: openMenu,
    };
};

export const setUserInfo = (payload: IUser, remember: boolean): IAction => {
    const payloadSaida = payload;
    payloadSaida.remember = remember;

    return {
        type: actionsTypes.SET_USER_INFO,
        payload: payloadSaida,
    };
};

export const setUserClean = (): IAction => {
    return {
        type: actionsTypes.SET_USER_CLEAN,
        payload: null,
    };
};

export const setToken = (token: string, remember: boolean): IAction => {
    return {
        type: actionsTypes.SET_TOKEN,
        payload: { token, remember },
    };
};
