/* eslint-disable default-param-last */
import { combineReducers, Reducer } from 'redux';
import * as actionTypes from './actionsTypes';
import { IAppState, ITemplateState } from './types';

const INIT_TEMPLATE_STATE: ITemplateState = {
    collapseMenu: localStorage.getItem('CollapseMenu') === 'true',
    isOpen: [],
    isTrigger: [],
};

const templateReducer: Reducer<ITemplateState> = (state = INIT_TEMPLATE_STATE, action) => {
    let open: string[] = [];
    let trigger: string[] = [];
    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: action.payload,
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter((item) => item !== action.menu.id);
                    trigger = trigger.filter((item) => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = state.isTrigger.indexOf(action.menu.id);
                trigger = triggerIndex === -1 ? [action.menu.id] : [];
                open = triggerIndex === -1 ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        default:
            return state;
    }
};

const INIT_APP_STATE: IAppState = {
    root: 'ROOT_LOADING',
    error: null,
    user: JSON.parse(localStorage.getItem('user') || '{}') || null,
    token: localStorage.getItem('jwttoken') || null,
};

const appReducer: Reducer<IAppState> = (state = INIT_APP_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_ROOT_VIEW: {
            return {
                ...state,
                root: action.payload,
            };
        }

        case actionTypes.SET_ERROR_API: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case actionTypes.SET_USER_INFO: {
            const newUser = { ...state.user, ...action.payload };
            if (action.payload.remember) {
                localStorage.setItem('user', JSON.stringify(newUser));
            }
            return {
                ...state,
                user: newUser,
            };
        }

        case actionTypes.SET_TOKEN: {
            if (action.payload.remember) {
                localStorage.setItem('jwttoken', action.payload.token);
            }
            return {
                ...state,
                token: action.payload.token,
            };
        }

        case actionTypes.SET_USER_CLEAN: {
            localStorage.removeItem('user');
            localStorage.removeItem('jwttoken');
            return {
                ...state,
                user: null,
                token: null,
            };
        }

        default:
            return state;
    }
};

const rootReducer = combineReducers({
    template: templateReducer,
    app: appReducer,
});

export default rootReducer;
