import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../components/Loader';

const LoginPage = React.lazy(() => import('../pages/Login'));

const OutsideStack = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path={['/account/login', '/*']} component={LoginPage} />
            </Switch>
        </Suspense>
    );
};

export default OutsideStack;
