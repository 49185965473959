function upperCaseText(str: string) {
    return str.toUpperCase();
}

export const capitalizeText = (value: string) =>
    value.charAt(0).toUpperCase() +
    value
        .toLowerCase()
        .slice(1)
        .replace(/\s[a-z]/g, upperCaseText);

export const emailFormat = (value: string) => value.replace(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, value);

export const cpfMask = (value: string) => value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

export const cnpjMask = (value: string) => value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');

export const cnpjAndCpfMask = (value: string) => {
    if (String(value).length === 14) {
        return cnpjMask(value);
    }
    if (String(value).length === 11) {
        return cpfMask(value);
    }
    return '';
};

export const thousandNumber = (value: number | string) => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const formatPhone = (phone: string) => phone.replace(/\D/g, '').replace(/(\d{2})(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4');

export const formatPhoneToNumber = (phone: string) => phone.replace(/\D/g, '');

export const currencyFormat = (value: number) => {
    return Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);
};

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formatCEP(str: string) {
    const re = /^([\d]{2})\.?([\d]{3})-?([\d]{3})/;

    if (re.test(str)) {
        return str.replace(re, '$1.$2-$3');
    }
    return str;
}

export const formatDate = (e) => {
    const maskRgx =
        // eslint-disable-next-line max-len
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    let { value } = e.currentTarget;
    if (!value.match(maskRgx)) {
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '$1/$2');
        value = value.replace(/(\d{2})(\d)/, '$1/$2');
        e.currentTarget.value = value;
    }
    return e;
};
