/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import './styles.scss';

interface IOptions {
    label: string;
    name: string;
}

interface IProps {
    className?: string;
    label?: string | any;
    options: IOptions[];
    multiple?: boolean;
    onSelect: (value: IOptions[]) => void;
    value?: IOptions[];
}

const ChoiceTag: React.FC<IProps> = ({ className, label, options = [], multiple = false, onSelect, value }) => {
    const [selected, setSelected] = useState<IOptions[]>([]);

    const handleClick = (elem: IOptions) => {
        let final: IOptions[];

        if (multiple) {
            final = selected.find((element) => element.name === elem.name) ? selected.filter((element) => element.name !== elem.name) : [...selected, elem];
        } else {
            final = [elem];
        }

        if (onSelect) {
            onSelect(final);
        }
    };

    useEffect(() => {
        if (value) setSelected(value);
    }, [value]);

    return (
        <div className={`choise-tag-abmex ${className}`}>
            <label>{label}</label>
            <div className="chips">
                {options.map((element: any, _) => (
                    <Chip
                        key={element.name}
                        onClick={() => handleClick(element)}
                        label={element.label ? element.label : element}
                        color="primary"
                        variant={selected.filter((item) => item.name === element.name).length > 0 ? 'default' : 'outlined'}
                    />
                ))}
            </div>
        </div>
    );
};

ChoiceTag.defaultProps = {
    className: '',
    label: '',
    multiple: false,
    value: [
        {
            label: '',
            name: '',
        },
    ],
};

export default ChoiceTag;
