/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { ReactComponent as ReactLogo } from 'assets/images/logo_novo.svg';
import { ReactComponent as ReactMenu } from 'assets/images/menu.svg';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { IoMenu } from 'react-icons/io5';
import { useWindowWidth } from '@react-hook/window-size';
import { FaBell, FaSignOutAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { setCollapseMenu } from 'redux/actions';
import { IApplicationState } from 'redux/store';
import { Dispatch } from 'redux';
import './styles.scss';

interface StateProps {
    collapseMenu: boolean;
}

interface DispatchProps {
    onToggleNavigation: (openMenu: boolean) => void;
}

interface Props {
    small?: boolean;
}

type IProps = StateProps & DispatchProps & Props;
const Header: React.FC<IProps> = React.memo(({ small = false, collapseMenu, onToggleNavigation }) => {
    // const [width, height] = useWindowSize();

    const { handleLogout } = useContext(AuthContext);
    const [openMenu, setOpenMenu] = React.useState(false);
    const history = useHistory();
    const width = useWindowWidth();

    return (
        <header className="menu-header">
            <div className="left">
                {!small && (
                    <button type="button" onClick={() => onToggleNavigation(!collapseMenu)} className="btn-menu" aria-label="btn-menu">
                        <IoMenu size={30} />
                    </button>
                )}
                <div className="logo">
                    <ReactLogo width="28px" className="ml-3" onClick={() => history.push('/')} style={{ cursor: 'pointer' }} />
                </div>
                <button type="button" className="btn-mobile-menu" onClick={() => setOpenMenu(!openMenu)} aria-label="btn-menu-mobile">
                    <ReactMenu width="28px" className="mr-3" />
                </button>
            </div>
            {openMenu || width > 992 ? (
                <div className="right">
                    <span>
                        <FaBell size={21} />
                    </span>
                    <span aria-hidden role="button" onClick={handleLogout}>
                        <FaSignOutAlt size={25} />
                    </span>
                </div>
            ) : null}
        </header>
    );
});

const mapStateToProps = (state: IApplicationState) => {
    return {
        collapseMenu: state.template.collapseMenu,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onToggleNavigation: (openMenu: boolean) => dispatch(setCollapseMenu(openMenu)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
