/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode } from 'react';
import './styles.scss';
import { IoIosArrowDown } from 'react-icons/io';

interface IProps {
    className?: string;
    width?: string;
    row?: boolean;
    hide?: boolean;
    onClick?: () => void;
    icon?: ReactNode;
    color?: string;
    label?: string;
    children?: React.ReactNode;
}

export const Table: React.FC<IProps> = ({ children, className = '' }) => {
    return <div className={`abmex-table ${className} `}>{children}</div>;
};

export const Header: React.FC<IProps> = ({ children, className = '' }) => {
    return <div className={`row table-topo ${className} `}>{children}</div>;
};

export const HeaderItem: React.FC<IProps> = ({ children, className = '', width }) => {
    return (
        <div className={`typogray-header ${(width ? '' : 'col ') + className}`} style={width ? { width } : {}}>
            {children}
        </div>
    );
};

export const BodyContent: React.FC<IProps> = ({ children, row, className = '' }) => {
    return (
        <div
            className={`
                table-corpo m-0 mb-1
                ${row ? 'row' : 'flex-column'} 
                ${className}
              `}
        >
            {children}
        </div>
    );
};

export const BodyBox: React.FC<IProps> = ({ children, hide, className = '', onClick }) => {
    return (
        <div
            aria-hidden
            onClick={onClick}
            className={`row table-box 
            ${className} ${hide ? 'hide' : ''} 
            ${typeof onClick === 'function' ? 'cursor' : ''} `}
        >
            {children}
        </div>
    );
};

export const BodyItem: React.FC<IProps> = ({ children, width, className = '', label }) => {
    return (
        <div className={`${className} ${label ? 'abmex-table-label' : ''} col`} style={width ? { width } : {}} data-label={label}>
            {children}
        </div>
    );
};

export const BodyAction: React.FC<IProps> = ({ children, width, className = '', label }) => {
    return (
        <div className={`col ${className} ${label ? 'abmex-table-label' : ''}`} style={width ? { width } : {}} data-label={label}>
            <div className="acoes">{children}</div>
        </div>
    );
};

export const BodyActionBox: React.FC<IProps> = ({ onClick, icon, color = 'default' }) => {
    return (
        <span aria-hidden className={color} onClick={onClick}>
            {icon}
        </span>
    );
};

export const BodyShowMore: React.FC<IProps> = ({ onClick }) => {
    return (
        <div aria-hidden onClick={onClick} className="p-2" style={{ cursor: 'pointer' }}>
            <IoIosArrowDown />
        </div>
    );
};
