import React from 'react';
import './styles.scss';

interface IProps {
    title: string;
    label: string;
    color?: 'default' | 'success';
    titleColor?: 'default' | 'success';
    inverter?: boolean;
}

const TextInfo: React.FC<IProps> = ({ title, label, color, titleColor, inverter }) => {
    return (
        <div className="abmex-text-info">
            <strong className={titleColor + (inverter ? 'inverter' : '')}>{title}</strong>
            <span className={color + (inverter ? ' inverter' : '')}>{label}</span>
        </div>
    );
};

TextInfo.defaultProps = {
    inverter: false,
    titleColor: 'default',
    color: 'default',
};

export default TextInfo;
