import { application, api } from '../axios.service';

interface IParams {
    status?: string;
    created_from?: string;
    created_to?: string;
    product_type_id?: number;
    text?: string;
    limit: number;
    offset: number;
}

export const ProductServices = {
    getApprovalsProducts: (params: IParams) => application.get('/approvals/products', { params }),
    get: (params: IParams) => application.get('/products', { params }),
    find: (productId: number) => application.get(`products/${productId}`),
    approved: (productId: number) => application.put(`/products/${productId}/approval`),
    disapproval: (productId: number, reasons: string) => application.put(`/products/${productId}/disapproval`, { reasons }),
    reactivate: (productId: number) => application.put(`/products/${productId}/reactivate`),
    acceptInternational: (productId: number, acceptsInternationalPayment: boolean) => application.put(`/products/${productId}/settings`, { acceptsInternationalPayment }),
    types: {
        list: () => api.get('product-types'),
    },
    getSelect: () => application.get('/select-products'),
};
