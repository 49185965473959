import React from 'react';
import { connect } from 'react-redux';
import * as S from './styles';
import userAvatar from '../../assets/images/default_avatar.png';
import { IApplicationState } from '../../redux/store';
import { IUser } from '../../redux/types';

interface IProps {
    user?: IUser | null;
}

const HeaderAvatar: React.FC<IProps> = ({ user }) => {
    return (
        <S.Container className="sidebar-avatar mb-4">
            <S.ContainerAvatar>{user?.avatar ? <S.AvatarImg src={user.avatar.url} /> : <S.AvatarImg src={userAvatar} />}</S.ContainerAvatar>

            <S.Texts className="text-center text-name">
                <S.Welcome>Seja bem-vindo(a),</S.Welcome>
                <S.Name>{user?.name}</S.Name>
            </S.Texts>
        </S.Container>
    );
};

function mapProps(state: IApplicationState) {
    return {
        user: state.app.user,
    };
}

HeaderAvatar.defaultProps = {
    user: null,
};

export default connect(mapProps)(HeaderAvatar);
