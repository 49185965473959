/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { GiPlainCircle } from 'react-icons/gi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { IApplicationState } from 'redux/store';
import * as actionTypes from 'redux/actionsTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IItemMenu } from 'routes.menu';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';

interface IProps {
    element;
    nameMain;
}

const NavItem: React.FC<IProps> = ({ element, nameMain }) => {
    const history = useHistory();
    let navItemClass: string[] = [];

    const currentIndex = document.location.pathname
        .toString()
        .split('/')
        .findIndex((id) => `${nameMain}-${id}` === element.id);

    if (currentIndex > -1) {
        navItemClass = [...navItemClass, 'active'];
    }

    if (element.disabled) {
        navItemClass = [...navItemClass, 'disabled'];
    }

    return (
        <li>
            <button className={`inside-button ${navItemClass.join(' ')}`} onClick={() => history.push(element.url)} type="button">
                <GiPlainCircle size={6} />
                {element.title}
            </button>
        </li>
    );
};

interface StateProps {
    isOpen: string[]; // todo: use the type of state.myList to have validation on it
    // isTrigger: string[];
}

interface DispatchProps {
    onCollapseToggle: (id: string, type: string) => void;
    // onNavCollapseLeave: (id: string, type: string) => void;
}

interface Props extends RouteComponentProps {
    item: IItemMenu;
}

type PropsType = StateProps & DispatchProps & Props;

const NavCollapse: React.FC<PropsType> = ({ item, onCollapseToggle, isOpen }) => {
    const history = useHistory();
    let subItens: any[] = [];

    if (item.children) {
        subItens = item.children.map((element) => <NavItem nameMain={item.id} element={element} key={element.id} />);
    }

    let navItemClass = ['nav-item'];

    const openIndex = isOpen.findIndex((id) => id === item.id);
    if (openIndex > -1) {
        navItemClass = [...navItemClass, 'active'];
    }

    const currentIndex = document.location.pathname
        .toString()
        .split('/')
        .findIndex((id) => id === item.id);

    if (currentIndex > -1) {
        navItemClass = [...navItemClass, 'active'];
    }

    const onClick = (e) => {
        e.preventDefault();
        onCollapseToggle(item.id, item.type);
        if (!item.children && item.url) {
            history.push(item.url);
        }
    };

    const subItensCurrent = subItens.filter((subItem) => subItem.props.nameMain === isOpen[0]);

    const contentItem = () => (
        <>
            <button className="nav-link" onClick={onClick} type="button">
                <span className="nav-icon">{item.icon}</span>
                <span className="nav-text">{item.title}</span>
                {item?.children && (
                    <span className="nav-right-icon">{isOpen[0] === subItens[0].props.nameMain ? <MdOutlineKeyboardArrowDown data-testid="down" /> : <MdOutlineKeyboardArrowRight data-testid="right" />}</span>
                )}
            </button>
            {isOpen.length > 0 && <ul className="subitem">{subItensCurrent}</ul>}
        </>
    );

    return <li className={navItemClass.join(' ')}>{contentItem()}</li>;
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        isOpen: state.template.isOpen,
        isTrigger: state.template.isTrigger,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onCollapseToggle: (id: string, type: string) =>
            dispatch({
                type: actionTypes.COLLAPSE_TOGGLE,
                menu: { id, type },
            }),
        onNavCollapseLeave: (id: string, type: string) =>
            dispatch({
                type: actionTypes.NAV_COLLAPSE_LEAVE,
                menu: { id, type },
            }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavCollapse));
