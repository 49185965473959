import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import UnavailableService from '../../components/UnavailableService';
import './styles.scss';
import { setErrorApi as setErrorApiRedux } from '../../redux/actions';
import { IApplicationState } from '../../redux/store';
import { IAction } from '../../redux/types';

interface IProps {
    error: Object | null;
    setErrorApi: (param: Object | null) => IAction;
}

const Modal: React.FC<IProps> = ({ error, setErrorApi }) => {
    const HandlerClose = () => {
        setErrorApi(null);
    };

    if (!error) return null;
    return (
        <div className="modal-erro-service">
            <div className="conteudo p-4 pb-5 animate__animated animate__headShake">
                <div className="d-flex justify-content-end">
                    <div aria-hidden role="button" className="box-icon" onClick={HandlerClose}>
                        <FaTimes />
                    </div>
                </div>
                <UnavailableService error={error} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        error: state.app.error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setErrorApi: (error: Object | null) => dispatch(setErrorApiRedux(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
