import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0 0;
`;

export const ContainerAvatar = styled.div`
    width: 40%;
    border-radius: 50%;
    margin-bottom: 10px;
`;

export const AvatarImg = styled.img`
    width: 100%;
    border-radius: 50%;
`;

export const Texts = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Welcome = styled.span`
    font-weight: normal;
    color: #808080;
    font-size: 0.7em;
    margin-top: 0.5em;
`;
export const Name = styled.span`
    width: 250px;
    font-weight: normal;
    color: #fff;
    font-size: 1em;
`;

export const Icons = styled.div`
    display: flex;
    justify-content: space-around;
`;
