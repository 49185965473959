import React, { JSXElementConstructor, ReactElement } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

interface IProps {
    onClick: () => void;
    color?: 'danger' | 'success' | 'warning' | 'info' | 'primary';
    children: string;
}

const LinkButton: React.FC<IProps> = ({ onClick, children, color }) => {
    let inputColor;
    switch (color) {
        case 'danger':
            inputColor = '#FF5252';
            break;
        case 'success':
            inputColor = '#15CE95';
            break;
        default:
            inputColor = '#FF7A01';
            break;
    }

    return (
        <S.Container onClick={onClick}>
            <S.Link color={inputColor}>{children}</S.Link>
        </S.Container>
    );
};

LinkButton.prototype = {
    onClick: PropTypes.func,
    color: PropTypes.oneOf(['danger', 'success']),
    children: PropTypes.element,
};

LinkButton.defaultProps = {
    color: undefined,
};

export default LinkButton;
