import { Box, Dialog } from '@material-ui/core';
import styled, { AnyStyledComponent } from 'styled-components';

export const ContainerDialog = styled(Dialog).attrs({
    fullScreen: true,
})`
    && div.MuiDialog-paperFullScreen {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 400px;
        max-height: none;
        border-radius: 0;
        float: right;
        position: relative;
    }
    && div.MuiDialog-container.MuiDialog-scrollPaper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const Wrapper = styled(Box as AnyStyledComponent).attrs({ component: 'div' })`
    padding: 2em;
    width: 100%;
    height: 100%;
`;

export const WrapperButton = styled.div`
    position: absolute;
    right: 2em;
`;

export const CloseModal = styled.button`
    border: none;
    background: transparent;
`;

export const Header = styled(Box as AnyStyledComponent).attrs({ component: 'div' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
`;

export const Title = styled.h5`
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    font-weight: 600;
`;
