import React from 'react';
import './styles.scss';

interface IProps {
    status: 'Automático' | 'Manual';
}

const StatusPrepayment: React.FC<IProps> = ({ status }) => {
    if (status === 'Automático') {
        return (
            <div className="status-item danger-dark">
                <span>Automático</span>
            </div>
        );
    }

    return (
        <div className="status-item neutral-dark">
            <span>Manual</span>
        </div>
    );
};

export { StatusPrepayment };
